body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
}

.skew-button {
  cursor: pointer;
  outline: none;
  transform: translate3d(-50%, -50%, 0);
  backface-visibility: hidden;
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
}

.skew-button > span {
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px 50px;
  z-index: 3;
}
.skew-button:before,
.skew-button > span:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) skewX(-20deg);
  width: 100%;
  height: 50px;
  border: 1px solid #0e3876;
  z-index: 2;
}

.skew-button > span:before {
  display: block;
  transform: translate(-50%, -50%) skew(-20deg);
  transform-origin: center center;
  z-index: -1;
}

.skew-button::after {
  transition: all 100ms ease-out;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  transform: skewX(-20deg) scaleX(0);
  width: 100%;
  height: 100%;
  background-color: #0e3876;
  border: 1px solid transparent;
  z-index: 1;
}

.skew-button:hover > span:before {
  animation: fill 0.5s ease-out;
  animation-fill-mode: forwards;
}

.skew-button:hover:after {
  animation: swoosh 0.7s ease-in;
}

.skew-button:active:before {
  background-color: darken(#0e3876, 20%);
}

@keyframes swoosh {
  0% {
    transform-origin: left center;
  }

  20% {
    transform: skewX(-20deg) scaleX(1);
    transform-origin: left center;
  }

  21% {
    transform-origin: right center;
  }

  50% {
    transform: skewX(-20deg) scaleX(0);
    transform-origin: right center;
  }
}

@keyframes fill {
  0% {
    background-color: rgba(#0e3876, 0);
  }

  20% {
    opacity: 0.5;
    border-width: 15px;
    border-color: darken(#0e3876, 10%);
  }

  100% {
    opacity: 0.5;
    border-width: 1px;
    background-color: rgba(#0e3876, 0.5);
  }
}

.canvas-container {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.canvas {
  width: 100%;
  max-width: 600px;
}

.download-btn {
  color: white;
  transform: translate(10px, 10px) scale(1.5);
}
